import React from "react";
import "./AddDashboard.css";
import AddDashboardIcon from "../../../../components/Icon/Icons/addDashboardIcon";

interface AddDashboardButtonProps {
  onAdd: () => void;
}

const AddDashboard: React.FC<AddDashboardButtonProps> = ({ onAdd }) => {
  return (
    <button onClick={onAdd} className="add-dashboard-button">
      <div className="add-dash-icon-container">
        <AddDashboardIcon fill="#f28332" />
      </div>
    </button>
  );
};

export default AddDashboard;
