import React from "react";
import "./Habits.css";

const Habits: React.FC = () => {
  return (
    <div className="habits-dashboard">
      <div className="title-bar">Habits Dashboard</div>
    </div>
  );
};

export default Habits;
