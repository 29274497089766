import React, { useState, ChangeEvent } from "react";
import "./ComboBox.css";

interface ComboBoxProps {
  label: string;
  data: string[];
  onChange: (selectedItems: string | string[]) => void;
  multiSelect?: boolean;
}

const ComboBox: React.FC<ComboBoxProps> = ({
  data,
  onChange,
  multiSelect = false,
  label = "Select Options",
}) => {
  const [selectedItems, setSelectedItems] = useState<string | string[]>(
    multiSelect ? [] : ""
  );

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = multiSelect
      ? Array.from(event.target.selectedOptions, (option) => option.value)
      : event.target.value;
    setSelectedItems(selectedOptions);
    onChange(selectedOptions);
  };

  return (
    <div className="combo-box-container">
      <label>{label}</label>
      <select
        multiple={multiSelect}
        value={selectedItems}
        onChange={handleChange}
        className="combo-box"
      >
        {data.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ComboBox;
