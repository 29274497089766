import React from "react";

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  dashboards: string[];
  token: string;
}

export interface UserContextProps {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const UserContext = React.createContext<UserContextProps | undefined>(
  undefined
);

export default UserContext;
