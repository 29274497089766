import React from "react";
import "./Home.css";

const Home: React.FC = () => {
  return (
    <div className="home-dashboard">
      <div className="title-bar">Home Dashboard</div>
    </div>
  );
};

export default Home;
