import React from "react";
import "./Finance.css";

const Finance: React.FC = () => {
  return (
    <div className="finance-dashboard">
      <div className="title-bar">Finance Dashboard</div>
    </div>
  );
};

export default Finance;
