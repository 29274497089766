import React from "react";
import NavItem from "./NavItem/NavItem";
import "./SideNav.css";
import UserContext from "../../../contexts/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../../../components/Icon/Icon";
import FinanceIcon from "../../../components/Icon/Icons/financeIcons";
import HabitsIcon from "../../../components/Icon/Icons/habitsIcon";
import AddDashboard from "./AddDashboard/AddDashboard";
import Avatar from "../../Avatar/Avatar";
import FitnessIcon from "../../../components/Icon/Icons/fitnessIcon";
import HomeIcon from "../../../components/Icon/Icons/homeIcon";
import LeftArrowIcon from "../../../components/Icon/Icons/leftArrowIcon";

const iconComponents: { [key: string]: React.FC<{ fill?: string }> } = {
  FinanceIcon,
  FitnessIcon,
  HabitsIcon,
  HomeIcon,
};

interface SideNavProps {
  isOpen: boolean;
  toggleNav: () => void;
}

const SideNav: React.FC<SideNavProps> = ({ isOpen, toggleNav }) => {
  const [dashboards, setDashboards] = React.useState<string[]>([]);
  const userContext = React.useContext(UserContext);
  const location = useLocation();

  const navigate = useNavigate();

  const toggleDrawer = () => {
    toggleNav();
  };

  if (!userContext) {
    throw new Error("UserContext is undefined");
  }

  const { user } = userContext;

  console.log(`Hello ${user?.firstName} ${user?.lastName}, you made it!`);

  React.useEffect(() => {
    if (user) {
      setDashboards(user.dashboards);
    }
  }, [user]);

  React.useEffect(() => {
    navigate("/dashboard/home");
  }, []);
  const handleAddDashboard = () => {
    console.log("TO-DO: Add dashboard...");
  };

  if (!dashboards || dashboards.length === 0) {
    return (
      <>
        <p>Something went wrong.</p>
      </>
    );
  }

  return (
    <div className={`side-nav ${isOpen ? "open" : ""}`}>
      <div className="side-nav-close-arrow">
        <button onClick={toggleDrawer}>
          <LeftArrowIcon />
        </button>
      </div>
      <div>
        <nav>
          {dashboards.map((dashboard) => {
            const dashboardTitleCase =
              dashboard.charAt(0) + dashboard.slice(1).toLowerCase();
            const IconComponent = iconComponents[`${dashboardTitleCase}Icon`];
            const path = `/dashboard/${dashboard.toLowerCase()}`;
            const isActive = location.pathname === path;
            return (
              <NavItem
                key={dashboard}
                name={dashboard}
                path={path}
                icon={
                  <Icon
                    IconComponent={IconComponent}
                    color={isActive ? "#fff" : undefined}
                  />
                }
                isActive={isActive}
              />
            );
          })}
          <AddDashboard onAdd={handleAddDashboard} />
        </nav>
      </div>
      <div className="avatar-container">
        <Avatar online={true} />
      </div>
    </div>
  );
};

export default SideNav;
