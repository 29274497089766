import React from "react";
import { Routes, Route } from "react-router-dom";
import SideNav from "../../design-system/Navigation/SideNav/SideNav";
import Finance from "../Dashboards/Finance/Finance";
import Fitness from "../Dashboards/Fitness/Fitness";
import "./BaseApp.css";
import Habits from "../Dashboards/Habits/Habits";
import Home from "../Dashboards/Home/Home";
import OpenNavButton from "../OpenNavButton/OpenNavButton";

const BaseApp: React.FC = () => {
  const [isNavOpen, setIsNavOpen] = React.useState(true);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className={`baseapp ${isNavOpen ? "nav-open" : ""}`}>
      <SideNav isOpen={isNavOpen} toggleNav={toggleNav} />
      {!isNavOpen && <OpenNavButton toggleNav={toggleNav} />}{" "}
      <div className={`dashboard ${isNavOpen ? "nav-open" : ""}`}>
        <Routes>
          <Route path="/fitness" element={<Fitness />} />
          <Route path="/finance" element={<Finance />} />
          <Route path="/habits" element={<Habits />} />
          <Route path="/home" element={<Home />} />
        </Routes>
      </div>
    </div>
  );
};

export default BaseApp;
