import React, { useState } from "react";
import Button from "../../design-system/Button/Button";
import PrivacyField from "../../design-system/PrivacyField/PrivacyField";
import TextField from "../../design-system/TextField/TextField";
import "./LogIn.css";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import { initializeApp } from "firebase/app";
import "firebase/auth";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { BASE_URL, USER_BY_ID_ENDPOINT, firebaseConfig } from "../../apiConfig";

const LogIn: React.FC = () => {
  let navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const userContext = React.useContext(UserContext);

  if (!userContext) {
    throw new Error("UserContext is undefined");
  }

  const { setUser } = userContext;

  const app = initializeApp(firebaseConfig);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const auth = getAuth(app);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        username,
        password
      );
      const firebaseUser = userCredential.user;

      if (firebaseUser) {
        const idToken = await firebaseUser.getIdToken();
        const response = await fetch(
          `${BASE_URL}${USER_BY_ID_ENDPOINT}${firebaseUser.uid}`,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Error fetching user");
        }

        const user = await response.json();

        setUser({ ...user, token: idToken });
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Error signing in: ", error);
    }
  };

  return (
    <div className="login-page">
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="login-image-container">
          <img
            src="/images/girl_running-no_bg.png"
            alt="My Image"
            className="login-image"
          />
        </div>
        <div className="login-fields">
          <div className="welcome-message">
            <div className="login-logo-container">
              <img src="/images/logo.svg" alt="Logo" className="logo" />
            </div>
            <span className="welcome-to">Welcome to </span>
            <span className="run-app">LifeHub</span>
          </div>
          <TextField
            placeholder="someemail@gmail.com"
            label="Username"
            value={username}
            onChange={setUsername}
            required
            autoFocus
          />
          <div className="password-field">
            <PrivacyField
              placeholder="Password"
              label="Password"
              value={password}
              onChange={setPassword}
              required
            />
          </div>
          <Button
            label="Log In"
            type="submit"
            disabled={!username || !password}
          />
          <div className="register-link">
            Don't have an account? <Link to="/register">Create an account</Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LogIn;
