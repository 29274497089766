import React from "react";
import "./Avatar.css";
import CameraIcon from "../../components/Icon/Icons/cameraIcon";
import AvatarIcon from "../../components/Icon/Icons/avatarIcon";

interface AvatarProps {
  online: boolean;
}

const Avatar: React.FC<AvatarProps> = ({ online }) => {
  const [avatar, setAvatar] = React.useState<string | null>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="avatar-wrapper">
      <div className="avatar" onClick={handleClick}>
        {avatar ? (
          <img src={avatar} alt="Avatar" className="avatar-image" />
        ) : (
          <AvatarIcon className="avatar-image" />
        )}
        <CameraIcon className="camera-icon" />
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileInputChange}
          style={{ display: "none" }}
        />
      </div>
      {online && <div className="online-indicator"></div>}
    </div>
  );
};

export default Avatar;
