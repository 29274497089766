import React, { useState } from "react";
import "./DataGrid.css";

export interface ColumnConfig<T> {
  header: string;
  key: keyof T;
  render?: (item: T) => React.ReactNode;
}

type Action<T> = {
  label: string;
  callback: (item: T) => void;
  condition?: (item: T) => boolean;
  color?: string;
};

interface DataGridProps<T> {
  data: T[];
  columns: ColumnConfig<T>[];
  onRowClick: (item: T) => void;
  actions?: Action<T>[];
}

interface Identifiable {
  id: string | number;
}

function DataGrid<T extends Identifiable>({
  data,
  columns,
  onRowClick,
  actions,
}: DataGridProps<T>) {
  const [sortKey, setSortKey] = useState<keyof T | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const sortedData = sortKey
    ? [...data].sort((a, b) => {
        const aValue = a[sortKey];
        const bValue = b[sortKey];

        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
        } else if (typeof aValue === "string" && typeof bValue === "string") {
          return sortOrder === "asc"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        } else {
          return 0;
        }
      })
    : data;

  const handleHeaderClick = (key: keyof T) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortKey(key);
      setSortOrder("asc");
    }
  };

  return (
    <div className="datagrid-container">
      <table>
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.key as string}
                onClick={() => handleHeaderClick(column.key)}
              >
                {column.header}
                {sortKey === column.key && (
                  <span className="sort-arrow">
                    {sortOrder === "asc" ? " ▲" : " ▼"}
                  </span>
                )}
              </th>
            ))}
            {actions && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item) => (
            <tr key={item.id} onClick={() => onRowClick(item)}>
              {columns.map((column) => (
                <td key={column.key as string}>
                  {column.render
                    ? column.render(item)
                    : (item[column.key] as React.ReactNode)}
                </td>
              ))}
              {actions && (
                <td>
                  {actions.map((action, index) => (
                    <button
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        action.callback(item);
                      }}
                      style={{ color: action.color }}
                    >
                      {action.label}
                    </button>
                  ))}
                </td>
              )}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={columns.length + 1}>
              Total items: {sortedData.length}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default DataGrid;
