import React, { useEffect } from "react";
import Button from "../../design-system/Button/Button";
import TextField from "../../design-system/TextField/TextField";
import "./SignUp.css";
import PrivacyField from "../../design-system/PrivacyField/PrivacyField";
import { Link, useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import "firebase/auth";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { BASE_URL, SIGNUP_ENDPOINT, firebaseConfig } from "../../apiConfig";
import UserContext from "../../contexts/UserContext";

const SignUp: React.FC = () => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [isValid, setIsValid] = React.useState(false);
  let navigate = useNavigate();

  const userContext = React.useContext(UserContext);
  if (!userContext) {
    throw new Error("UserContext is undefined");
  }

  const { setUser, user } = userContext;

  const app = initializeApp(firebaseConfig);

  // Sign up with Firebase
  // And then send a request to the backend to create a user
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const auth = getAuth(app);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const token = await userCredential.user.getIdToken();

      // Send a request to your backend sign-up endpoint with the user information and the JWT token
      const response = await fetch(`${BASE_URL}${SIGNUP_ENDPOINT}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
        }),
      });

      const data = await response.json();
      setUser(data);
    } catch (error) {
      console.error("Error signing up: ", error);
    }
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    navigate("/dashboard");
  }, [user]);

  // Manual validation check - TO-DO: replace with Formik or React Hook Form
  React.useEffect(() => {
    if (
      firstName &&
      lastName &&
      email &&
      password &&
      password === verifyPassword
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [firstName, lastName, email, password, verifyPassword]);

  // More manual validation
  React.useEffect(() => {
    if (password && verifyPassword && password !== verifyPassword) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError("");
    }
  }, [password, verifyPassword]);

  return (
    <div className="signup-page">
      <form className="signup-form" onSubmit={handleSubmit}>
        <div className="create-account-message">
          <span>Create an account </span>
        </div>
        <div className="signup-fields">
          <TextField
            label="First Name"
            value={firstName}
            onChange={setFirstName}
            required
            autoFocus
          />
          <TextField
            label="Last Name"
            value={lastName}
            onChange={setLastName}
          />
          <TextField label="Email" value={email} onChange={setEmail} required />
          <PrivacyField
            label="Password"
            value={password}
            onChange={setPassword}
            required
          />
          <PrivacyField
            label="Verify Password"
            value={verifyPassword}
            onChange={setVerifyPassword}
            required
          />
          {passwordError && (
            <div className="text-field-error">{passwordError}</div>
          )}
          <Button label="Sign Up" type="submit" disabled={!isValid} />
          <div className="signup-link">
            Already have an account? <Link to="/login">Log in</Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
