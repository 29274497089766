import React from "react";
import { useNavigate } from "react-router-dom";
import "./NavItem.css";

interface NavItemProps {
  name: string;
  path: string;
  icon: JSX.Element;
  isActive: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ name, path, icon, isActive }) => {
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate(path)} className={isActive ? "active" : ""}>
      {icon}
    </button>
  );
};

export default NavItem;
