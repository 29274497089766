import React from "react";

interface IconProps {
  color?: string;
  IconComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const Icon: React.FC<IconProps> = ({ color, IconComponent }) => (
  <IconComponent fill={color} />
);

export default Icon;
