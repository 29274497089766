import React from "react";
import "./TextField.css";

interface TextFieldProps {
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
  required?: boolean;
  type?: string;
  autoFocus?: boolean;
}

const TextField: React.FC<TextFieldProps> = ({
  label,
  placeholder,
  value,
  onChange,
  required,
  type = "text",
  autoFocus = false,
}) => {
  const [error, setError] = React.useState("");
  const [touched, setTouched] = React.useState(false);
  const [hasChanged, setHasChanged] = React.useState(false);

  React.useEffect(() => {
    if (hasChanged && touched && required && !value) {
      setError(`${label} is required`);
    } else {
      setError("");
    }
  }, [value, required, label, touched, hasChanged]);

  return (
    <div className="text-field-container">
      {label && (
        <label>
          {label}
          {required && <span className="required-asterisk">*</span>}
        </label>
      )}
      <input
        className="text-field"
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange && onChange(e.target.value);
          setHasChanged(true);
        }}
        onBlur={() => setTouched(true)}
        autoFocus={autoFocus}
      />
      {error && (
        <div className={`text-field-error ${error ? "show" : ""}`}>{error}</div>
      )}
    </div>
  );
};

export default TextField;
