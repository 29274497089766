// App.tsx
import React from "react";
import Router from "./components/Router/Router";
import UserContext, { User } from "./contexts/UserContext";

const App: React.FC = () => {
  const [user, setUser] = React.useState<User | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Router />
    </UserContext.Provider>
  );
};

export default App;
