// BASE URL
export const BASE_URL = "https://www.life-hub-service.com:8080";

// ENDPOINTS
export const SIGNUP_ENDPOINT = "/api/users/signup";
export const USER_BY_ID_ENDPOINT = "/api/users/";
export const RUNS_ENDPOINT = "/api/runs";
export const TERRAINS_ENDPOINT = "/api/runs/terrains";
export const WEATHER_ENDPOINT = "/api/runs/weather-types";

// FIREBASE CONFIG
export const firebaseConfig = {
    apiKey: "AIzaSyBN9eTodL0XC1onUh3R7wQou38LwdDfSC4",
    authDomain: "life-hub-app.firebaseapp.com",
    projectId: "life-hub-app",
    storageBucket: "life-hub-app.appspot.com",
    messagingSenderId: "426329584653",
    appId: "1:426329584653:web:3a0e9478c273654c4c5144",
    measurementId: "G-7GKZ8J6R1S",
  };