import React from "react";
import "./Fitness.css";
import RunDataGridWidget from "../../Widgets/RunDataGridWidget/RunDataGridWidget";

const Fitness: React.FC = () => {
  return (
    <div className="fitness-dashboard">
      <div className="title-bar">Fitness Dashboard</div>
      <RunDataGridWidget />
    </div>
  );
};

export default Fitness;
