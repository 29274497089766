import React, { ReactNode } from "react";
import "./WidgetContainer.css";

interface WidgetContainerProps {
  children: ReactNode;
}

const WidgetContainer: React.FC<WidgetContainerProps> = ({ children }) => {
  return <div className="widget-container">{children}</div>;
};

export default WidgetContainer;
