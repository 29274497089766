import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "../LogIn/LogIn";
import BaseApp from "../BaseApp/BaseApp";
import SignUp from "../SignUp/SignUp";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/dashboard/*" element={<BaseApp />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
