import React from "react";
import "./DurationPicker.css";

type DurationPickerProps = {
  duration: string;
  onChange: (value: string) => void;
};

const DurationPicker: React.FC<DurationPickerProps> = ({
  duration,
  onChange,
}) => {
  const hours = Array.from({ length: 24 }, (_, i) => i);
  const minutesAndSeconds = Array.from({ length: 60 }, (_, i) => i);

  const [hoursValue, minutesValue, secondsValue] = duration.split(":");
  const hoursDisplay = hoursValue || "00";
  const minutesDisplay = minutesValue || "00";
  const secondsDisplay = secondsValue || "00";

  return (
    <div className="duration-picker-container">
      <label>
        Duration:
        <div className="duration-picker">
          <select
            value={hoursDisplay}
            onChange={(e) =>
              onChange(`${e.target.value}:${minutesDisplay}:${secondsDisplay}`)
            }
          >
            {hours.map((hour) => (
              <option key={hour} value={hour}>
                {hour.toString().padStart(2, "0")}
              </option>
            ))}
          </select>
          :
          <select
            value={minutesDisplay}
            onChange={(e) =>
              onChange(`${hoursDisplay}:${e.target.value}:${secondsDisplay}`)
            }
          >
            {minutesAndSeconds.map((minute) => (
              <option key={minute} value={minute}>
                {minute.toString().padStart(2, "0")}
              </option>
            ))}
          </select>
          :
          <select
            value={secondsDisplay}
            onChange={(e) =>
              onChange(`${hoursDisplay}:${minutesDisplay}:${e.target.value}`)
            }
          >
            {minutesAndSeconds.map((second) => (
              <option key={second} value={second}>
                {second.toString().padStart(2, "0")}
              </option>
            ))}
          </select>
        </div>
      </label>
    </div>
  );
};

export default DurationPicker;
