import React, { useEffect, useState } from "react";
import "./OpenNavButton.css";
import MenuIcon from "../Icon/Icons/menuIcon";

interface OpenNavButtonProps {
  toggleNav: () => void;
}

const OpenNavButton: React.FC<OpenNavButtonProps> = ({ toggleNav }) => {
  const [slideOut, setSlideOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setSlideOut(true), 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <button
      className={`open-nav-button ${slideOut ? "slide-out" : ""}`}
      onClick={toggleNav}
    >
      <MenuIcon />
    </button>
  );
};

export default OpenNavButton;
