import React from "react";
import TextField from "../TextField/TextField";

interface PrivacyFieldProps {
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
  required?: boolean;
}

const PrivacyField: React.FC<PrivacyFieldProps> = ({
  label,
  placeholder,
  value,
  onChange,
  required,
}) => {
  return (
    <TextField
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      required={required}
      type="password"
    />
  );
};

export default PrivacyField;
