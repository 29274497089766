import React, { useState } from "react";
import Modal from "../../design-system/Modal/Modal";
import { Run } from "../Widgets/RunDataGridWidget/RunDataGridWidget";
import TextField from "../../design-system/TextField/TextField";
import Button from "../../design-system/Button/Button";
import "./AddRunModal.css";
import UserContext from "../../contexts/UserContext";
import {
  BASE_URL,
  RUNS_ENDPOINT,
  TERRAINS_ENDPOINT,
  WEATHER_ENDPOINT,
} from "../../apiConfig";
import DurationPicker from "../../design-system/DurationPicker/DurationPicker";
import ComboBox from "../../design-system/ComboBox/ComboBox";
import { useFetch } from "../../hooks/useFetch/useFetch";

interface AddRunModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const getDefaultRun = () => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const today = new Date();
  const dayOfWeek = daysOfWeek[today.getDay()];

  const localMidnight = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  return {
    title: `${dayOfWeek} Run`,
    date: localMidnight.toISOString().split("T")[0],
    distance: 1.0,
    duration: "00:00:00",
    caloriesBurnt: 0,
    terrain: "Trail",
    weatherConditions: null,
    notes: null,
  };
};

const AddRunModal: React.FC<AddRunModalProps> = ({ isOpen, onClose }) => {
  const userContext = React.useContext(UserContext);

  const [run, setRun] = useState(getDefaultRun());

  if (!userContext) {
    throw new Error("UserContext is undefined");
  }

  const { user } = userContext;

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    fetch(`${BASE_URL}${RUNS_ENDPOINT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
      body: JSON.stringify(run),
    })
      .then((response) => response.json())
      .then((data) => {
        onClose();
      })
      .catch((error) => {
        console.error("Error:", error);
        // handle error here
      });
  };

  const handleChange = (
    field: keyof Run,
    value: string | number | string[]
  ) => {
    setRun((prev) => ({ ...prev, [field]: value }));
  };

  const options = React.useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    }),
    [user]
  );

  const { data: terrains } = useFetch(
    `${BASE_URL}${TERRAINS_ENDPOINT}`,
    options
  );

  const { data: weatherConditions } = useFetch(
    `${BASE_URL}${WEATHER_ENDPOINT}`,
    options
  );

  React.useEffect(() => {
    if (isOpen) {
      setRun(getDefaultRun());
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2>Add Run</h2>
      <form onSubmit={handleSubmit} className="add-run-modal-form">
        <div className="add-run-modal-row">
          <TextField
            label="Title"
            value={run.title}
            onChange={(value) => handleChange("title", value)}
          />
          <TextField
            label="Date"
            type="date"
            value={run.date}
            onChange={(value) => handleChange("date", value)}
          />
          <TextField
            label="Distance"
            value={`${run.distance}`}
            type="number"
            onChange={(value) => handleChange("distance", Number(value))}
          />
          <DurationPicker
            duration={run.duration}
            onChange={(value) => handleChange("duration", value)}
          />
        </div>
        <div className="add-run-modal-row">
          <TextField
            label="Calories Burnt"
            type="number"
            value={`${run.caloriesBurnt}` || ""}
            onChange={(value) => handleChange("caloriesBurnt", value)}
          />
          <ComboBox
            label="Terrain"
            data={terrains}
            onChange={(value) => handleChange("terrain", value)}
          />
          <ComboBox
            label="Weather Conditions"
            data={weatherConditions}
            multiSelect
            onChange={(value) => handleChange("weatherConditions", value)}
          />
          <TextField
            label="Notes"
            value={run.notes || ""}
            onChange={(value) => handleChange("notes", value)}
          />
        </div>
        <div className="add-run-modal-button-container">
          <Button label="Add" type="submit" />
          <Button label="Cancel" onClick={onClose} />
        </div>
      </form>
    </Modal>
  );
};

export default AddRunModal;
