import React, { useEffect, useState } from "react";
import DataGrid, {
  ColumnConfig,
} from "../../../design-system/DataGrid/DataGrid";
import { BASE_URL, RUNS_ENDPOINT } from "../../../apiConfig";
import UserContext from "../../../contexts/UserContext";
import "./RunDataGridWidget.css";
import WidgetContainer from "../../../design-system/WidgetContainer/WidgetContainer";
import Button from "../../../design-system/Button/Button";
import AddRunModal from "../../AddRunModal/AddRunModal";
import { useFetch } from "../../../hooks/useFetch/useFetch";

export interface Run {
  id: string;
  pace: string;
  title: string;
  date: string;
  distance: number;
  duration: string;
  caloriesBurnt: number;
  terrain: string;
  weatherConditions: string[];
  notes: string;
  shoeId: number;
}

const RunDataGridWidget: React.FC = () => {
  const userContext = React.useContext(UserContext);
  const [isAddRunModalOpen, setIsAddRunModalOpen] = useState(false);

  if (!userContext) {
    throw new Error("UserContext is undefined");
  }

  const { user } = userContext;

  const actions = [
    {
      label: "Delete",
      callback: (run: Run) => {
        fetch(`${BASE_URL}${RUNS_ENDPOINT}/${run.id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            // Refresh the runs after a successful delete
            refetch();
          })
          .catch((error) => {
            console.error(
              "There has been a problem with your fetch operation:",
              error
            );
          });
      },
      condition: () => true,
      color: "red",
    },
  ];

  const options = React.useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    }),
    [user]
  );

  const { data: runsData, refetch } = useFetch(
    `${BASE_URL}${RUNS_ENDPOINT}`,
    options
  );

  const columns: ColumnConfig<Run>[] = [
    { header: "Title", key: "title" },
    {
      header: "Date",
      key: "date",
      render: (item: Run) => {
        const date = new Date(item.date);
        date.setUTCHours(12);

        return date.toLocaleDateString();
      },
    },
    { header: "Distance (mi)", key: "distance" },
    { header: "Duration", key: "duration" },
    { header: "Avg. Pace", key: "pace" },
    { header: "Calories Burnt", key: "caloriesBurnt" },
    {
      header: "Terrain",
      key: "terrain",
      render: (item: Run) => {
        const terrain = item.terrain.toLowerCase();
        return terrain.charAt(0).toUpperCase() + terrain.slice(1);
      },
    },
    {
      header: "Weather Conditions",
      key: "weatherConditions",
      render: (item: Run) => {
        if (!item.weatherConditions) {
          return "";
        }
        return item.weatherConditions.join(", ");
      },
    },
    { header: "Notes", key: "notes" },
  ];

  return (
    <div className="run-data-grid">
      <WidgetContainer>
        <div className="button-container">
          <Button label="Add Run" onClick={() => setIsAddRunModalOpen(true)} />
        </div>
        <DataGrid
          data={runsData}
          columns={columns}
          onRowClick={(run: Run) => console.log(run)}
          actions={actions}
        />
      </WidgetContainer>
      <AddRunModal
        isOpen={isAddRunModalOpen}
        onClose={() => {
          setIsAddRunModalOpen(false);
          refetch();
        }}
      />
    </div>
  );
};

export default RunDataGridWidget;
