import { useState, useEffect, useCallback } from 'react';
import JSONbig from 'json-bigint';

export const useFetch = (url: string, options: RequestInit) => {
    const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    fetch(url, options)
      .then((response) => response.text())
      .then((text) => JSONbig.parse(text))
      .then((data) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, [url, options]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, error, isLoading, refetch: fetchData };
};